import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  DadosAbertosList,
  DadosAbertosProps,
  OpenDataItensProps,
} from "./openData-list";
import InfoIcon from "@mui/icons-material/Info";
import { getServerData } from "../../util/get-server-data";

export const openData: OpenDataItensProps[] = [
  {
    text: "CREDORES",
    icon: InfoIcon,
    servicos: [
      {
        content: "Tipos de Credores",
        description: "Tipos possíveis de Credores.",
        slug: "credores/tipos",
      },
    ],
  },
  {
    text: "DESPESAS",
    icon: InfoIcon,
    servicos: [
      {
        content: "Despesas do Estado",
        description: "Lista de Despesas do Estado.",
        slug: "despesas/estado",
      },
    ],
  },
  {
    text: "DOCUMENTOS",
    icon: InfoIcon,
    servicos: [
      {
        content: "Documentos do Estado",
        description: "Documentos Estaduais.",
        slug: "documentos/estado",
      },
      {
        content: "Total de documentos do Estado",
        description: "Quantidade total de documentos estaduais.",
        slug: "documentos/estado/quantidadeTotal",
      },
    ],
  },
  {
    text: "LICITAÇÕES",
    icon: InfoIcon,
    servicos: [
      {
        content: "Licitações do Estado",
        description: "Licitações do Estado.",
        slug: "licitacoes/estado",
      },
    ],
  },
  {
    text: "ÓRGÃOS",
    icon: InfoIcon,
    servicos: [
      {
        content: "Prefeituras",
        description: "Lista todos os Municípios do Estado do Piauí.",
        slug: "prefeituras",
      },
    ],
  },
  {
    text: "RECEITAS",
    icon: InfoIcon,
    servicos: [
      {
        content: "Receitas do Estado",
        description: "Lista de Receitas do Estado.",
        slug: "receitas/estado",
      },
    ],
  },
  {
    text: "SERVIDORES",
    icon: InfoIcon,
    servicos: [
      {
        content: "Lista de Servidores",
        description: "Lista de servidores do TCE.",
        slug: "servidores/tce/lista",
      },
      {
        content: "Quantidade Total de Servidores",
        description: "Quantidade de servidores do TCE.",
        slug: "servidores/tceqtd",
      },
    ],
  },
];

export const DadosAbertosListContainer: FC = () => {
  let history = useHistory();

  const [isMounted, setIsMounted] = useState(false);

  const [openDataList, setOpenDataList] = useState<OpenDataItensProps[]>([]);
  const [openDataListFiltered, setOpenDataListFiltered] = useState<
    OpenDataItensProps[]
  >([]);

  const onClickCard = useCallback(
    (slug) => {
      history.push(`/dados/${slug}`);
    },
    [history]
  );

  const onClickSearch = useCallback(
    (filter) => {
      const filtered = openDataList.filter(
        ({ text }) => text?.toUpperCase()?.indexOf(filter?.toUpperCase()) !== -1
      );

      setOpenDataListFiltered(filtered);
    },
    [openDataList]
  );

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);

      const parsedData = getServerData("openDataList", []);

      if (parsedData?.length === 0) {
        setOpenDataList(openData);
        setOpenDataListFiltered(openData);
      } else {
        setOpenDataList(parsedData);
      }
    }
  }, [isMounted]);

  const newsProps: DadosAbertosProps = {
    onClick: onClickCard,
    onClickSearch,
    itens: openDataListFiltered,
  };

  return <DadosAbertosList {...newsProps} />;
};
