import axios from "axios";

export const apiUrl = "https://sistemas.tce.pi.gov.br/api/portaldacidadania";
const axiosClient = axios.create({
  baseURL: `${apiUrl}`,
  timeout: 0,
  headers: {},
});
export async function getDadosAbertos(
  resources: string,
  category: string = "",
  query: string = ""
): Promise<any> {
  try {
    const { data } = await axiosClient.get(
      `/${resources}/${category}/${query}`,
      {
        headers: {
          "Accept-Encoding": "gzip, deflate, br",
          Accept: "application/json, text/plain, */*",
        },
      }
    );
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
