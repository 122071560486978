import React, { FC } from "react";
import { Col, Row } from "antd";
import { Container } from "../../components/container/container";
import { SectionTitle } from "../../components/section-title/section-title";
import { OmbudsmanCard } from "./ombudman-card/ombudsman-card";
import { SvgIconComponent } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";

export interface OmbudsmanCardProps {
  text: string;
  icon: SvgIconComponent;
  onClick: () => void;
}

export interface OmbudsmanProps {
  itens?: OmbudsmanCardProps[];
}

export const Ombudsman: FC<OmbudsmanProps> = ({ itens }) => {
  return (
    <div className="xvia-ombudsman">
      <Container
        className="xvia-ombudsman__container"
        style={{ marginBottom: "2rem" }}
      >
        <SectionTitle text={"Ouvidoria"} />

        <Grid container spacing={2} columns={14}>
          {itens?.map((i) => {
            return (
              <Grid
                xs={14}
                md={2}
                className="xvia-ombudsman__card"
                onClick={i.onClick}
              >
                <OmbudsmanCard icon={i.icon} detail={i.text} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};
