import React, { FC, useCallback, useEffect, useState } from "react";
import { LoginButton, LoginButtonProps } from "./LoginButton";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useWebgate } from "@xvia/webgate-connect";

export const LoginButtonContainer: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const isMobileWidth = useMediaQuery("(max-width: 991px)");
  const { portalConnect, userInfo, accessToken } = useWebgate();

  useEffect(() => {
    setLoading(true);
    if (!accessToken) {
      return;
    }

    setLoading(false);
  }, [portalConnect, userInfo, accessToken]);

  useEffect(() => {
    if (!!accessToken) {
      setLoading(true);
      return;
    }
    setLoading(false);
  }, [accessToken, userInfo]);

  const onClickLogin = useCallback(() => {
    portalConnect?.login({
      redirectUri: `${process.env.PORTAL_URL}/app/dashboard`,
    });
  }, [portalConnect]);

  const onClickLogout = useCallback(() => {
    portalConnect?.logout({});
  }, [portalConnect]);

  const onAccountManagementRequest = useCallback(
    () => portalConnect?.accountManagement(),
    [portalConnect]
  );

  const onNotificationsRequest = useCallback(
    () => history.push("/notifications"),
    [history]
  );

  const onDashboardRequest = useCallback(() => {
    portalConnect?.redirectTo("/app/dashboard");
  }, [portalConnect]);

  const props: LoginButtonProps = {
    onClickLogin,
    onClickLogout,
    onAccountManagementRequest,
    onNotificationsRequest,
    onDashboardRequest,
    userInfo,
    loading,
    isMobile: isMobileWidth,
  };

  return <LoginButton {...props} />;
};
