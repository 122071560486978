import React, { FunctionComponent } from "react";
import "./floating-header.css";
import { Container } from "@mui/material";
import { Paragraph } from "../../components/typography/paragraph";

export interface FloatingHeaderProps {
  RightComponent: FunctionComponent;
}

export const FloatingHeader = ({ RightComponent }: FloatingHeaderProps) => {
  return (
    <div className="xvia-catalog-floating-header">
      <Container className="xvia-catalog-floating-header__container">
        <div className="xvia-catalog-floating-header__image-wrapper">
          <img
            src={`${process.env.PUBLIC_URL}/images/svgs/logo_white.svg`}
            className="xvia-catalog-floating-header__image"
            alt="Governo do Estado do Piauí"
          />
        </div>
        <div
          className="xvia-catalog-floating-header__links"
          style={{ display: "none" }}
        >
          <Paragraph>Serviços rápidos:</Paragraph>
          <ul>
            <li>
              <a href="">IPVA</a>
            </li>
            <li>
              <a href="">CNH</a>
            </li>
            <li>
              <a href="">Carteira do SUS</a>
            </li>
            <li>
              <a href="">Multas</a>
            </li>
          </ul>
        </div>
        <div className="xvia-catalog-floating-header__elements">
          <RightComponent />
        </div>
      </Container>
    </div>
  );
};
