import React, { FC, useCallback } from "react";
import { NewsCard } from "./news-card/news-card";
import { Container } from "../../components/container/container";

import { SectionTitle } from "../../components/section-title/section-title";
import { CarrocelNews } from "../../components/carousel/carrocel-news";

import { News } from "../../apis/news-rss";

import "./news-list.css";
export interface NewsProps {
  newsList: News[];
  onNewsClick: (news: News) => any;
  onSeeMoreClick?: () => any;
}

const colors = ["#007932", "#FDB913", "#EF4123", "#034EA2"];

export const NewsList: FC<NewsProps> = ({
  newsList,
  onNewsClick,
  onSeeMoreClick,
}) => {
  const onNewsClickHandler = useCallback(
    (news: News) => {
      return () => {
        onNewsClick(news);
      };
    },
    [onNewsClick]
  );

  if (!newsList) return null;

  return (
    <div className="xvia-news">
      <Container className="xvia-news__container">
        <SectionTitle
          text={"Últimas notícias"}
          linkText={"Ver tudo"}
          linkUrl={`/app/catalog/list/ultimas-noticias`}
        />
        <div>
          <CarrocelNews>
            {newsList.map((news, index) => {
              return (
                <div className="xvia-news__card">
                  <NewsCard
                    title={news.title}
                    onClick={onNewsClickHandler(news)}
                    imageUrl={news.imageUrl}
                    categoryName={news.categoryName}
                    color={colors[index % colors.length]}
                  />
                </div>
              );
            })}
          </CarrocelNews>
        </div>
      </Container>
    </div>
  );
};
