import React, { FC } from "react";
export interface ExternalLinkProps {
  link: string;
}

export const ExternalLink: FC<ExternalLinkProps> = ({ link, children }) => (
  <a href={link} target="_blank" rel="noreferrer">
    {children}
  </a>
);
