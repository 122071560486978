import axios from "axios";
import { CatalogDetail } from "./detail-catalog";

const apiUrl = process.env.BACKEND_ENDPOINT || "";

const axiosClient = axios.create({
  baseURL: `${apiUrl}`,
  timeout: 0,
  headers: {},
});

export interface CatalogRating {
  id?: string;
  value: number;
  catalogItem: CatalogDetail;
  createdAt: string;
  updatedAt: string;
}

export interface CatalogRatingResponse {
  id: string;
}

export async function postCatalogRating(
  catalogId: string,
  rating: Partial<CatalogRating>
): Promise<CatalogRatingResponse> {
  const { value } = rating;
  const endpoint = `/v1/catalog/${catalogId}/ratings`;
  const response = await axiosClient.post(endpoint, { value });

  return response.data;
}
