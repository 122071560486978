import React, { FC } from "react";
import "./section-title.css";

export interface BreakPageInfo {
  text: string;
  linkText?: string;
  linkUrl?: string;
}

export const SectionTitle: FC<BreakPageInfo> = ({
  text,
  linkText,
  linkUrl,
}) => {
  return (
    <span className={"xvia-section-title"}>
      <div className="xvia-section-title__text">{text}</div>
      <a className="xvia-section-title__link-text" href={linkUrl}>
        {linkText}
      </a>
    </span>
  );
};
