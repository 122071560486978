import React, { FC } from "react";
import { Typography, Box } from "@mui/material";
import classNames from "classnames";
import "./sidebar-card.css";

export interface SidebarCardProps {
  title?: string;
  icon?: string;
  className?: string;
  backgroundColor?: string;
}

export const SidebarCard: FC<SidebarCardProps> = ({
  title,
  className = "",
  backgroundColor = "",
  children,
}) => {
  const containerClassName = classNames({
    "xvia-card": true,
    [className]: !!className,
  });
  return (
    <Box className={containerClassName}>
      {title && (
        <Typography
          className="xvia-sidebar-card__title"
          style={{ backgroundColor }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};
