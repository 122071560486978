import React, { FC, useCallback, useEffect, useState } from "react";
import { RelevantServices, RelevantServicesProps } from "./relevant-services";
import { useWebgate } from "@xvia/webgate-connect";
import { Catalog } from "../../apis/search-catalog";
import { getServerData } from "../../util/get-server-data";
import { getRelevant } from "../../apis/relevant-catalog";
import { useHistory } from "react-router-dom";

export const RelevantServicesContainer: FC = () => {
  const { accessToken, serverData } = useWebgate();
  let history = useHistory();
  const [relevantCatalogList, setRelevantCatalogList] = useState<Catalog[]>(
    serverData?.relevant || getServerData("relevant", null)
  );
  const getServerRelevant = useCallback(() => {
    getRelevant(accessToken)
      .then((result) => {
        setRelevantCatalogList(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [accessToken]);

  const onClickCard = useCallback(
    (categorySlug, slug) => {
      history.push(`${categorySlug}/${slug}`);
    },
    [history]
  );

  useEffect(() => {
    if (!relevantCatalogList?.length) getServerRelevant();
  }, [getServerRelevant, relevantCatalogList]);

  const relevantServicesProps: RelevantServicesProps = {
    services: relevantCatalogList?.map((catalog) => {
      return {
        title: catalog.title,
        description: catalog.description,
        categoryIcon: catalog.icon,
        onClick: () => {
          onClickCard(catalog.categorySlug, catalog.slug);
        },
        imageUrl: catalog.imageUrl,
        onClickFavourite: () => {},
        type: catalog.isOnline ? "online" : "in-person",
        targets: catalog?.targets,
        accessProfile: catalog?.accessProfile,
      };
    }),
  };

  return <RelevantServices {...relevantServicesProps} />;
};
