import React, { FC, useCallback } from "react";
import { SocialNetwork, SocialNetworkProps } from "./social-network";
import {
  faInstagram,
  faYoutube,
  faSpotify,
  faReadme,
} from "@fortawesome/free-brands-svg-icons";

export const SocialNetworkContainer: FC = () => {
  const openLink = useCallback((link) => {
    window.open(link, "_blank");
  }, []);

  const props: SocialNetworkProps = {
    itens: [
      {
        text: "Siga nosso perfil no Instagram",
        icon: faInstagram,
        detail: "Solicite acesso a informações públicas",
        onClick: () => {
          openLink("https://instagram.com/governodopiaui");
        },
      },
      {
        text: "Se inscreva em nosso canal no YouTube",
        icon: faYoutube,
        detail: "Solicite acesso a informações públicas",
        onClick: () => {
          openLink("https://www.youtube.com/channel/UCUvn6mz9Su40_xqyZjK5Onw");
        },
      },
      {
        text: "Ouça a rádio CCOM",
        icon: faSpotify,
        detail: "Solicite acesso a informações públicas",
        onClick: () => {
          openLink("https://open.spotify.com/show/64ZqwVczZjJyz27PvxddqZ");
        },
      },
      {
        text: "Confira nossas publicações",
        icon: faReadme,
        detail: "Solicite acesso a informações públicas",
        onClick: () => {
          openLink("https://issuu.com/jornalismoccom");
        },
      },
    ],
  };

  return <SocialNetwork {...props} />;
};
