import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
    fontSize: undefined,
    fontWeightRegular: undefined,
    htmlFontSize: undefined,
  },
});
