import {
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LoginArea, LoginAreaProps } from "./login-area";
import { useWebgate } from "@xvia/webgate-connect";

export const LoginAreaContainer = () => {
  const { portalConnect, userInfo, accessToken } = useWebgate();
  const [isLoggedIn, setLoggedIn] = useState(false);

  const onClickDashboard = useCallback(() => {
    document.location.href = "/app/dashboard";
  }, []);

  const onClickLogin = useCallback(() => {
    portalConnect?.login({
      redirectUri: `${process.env.PORTAL_URL}/app/dashboard`,
    });
  }, [portalConnect]);

  const onClickLogout = useCallback(() => {
    portalConnect?.logout({});
  }, [portalConnect]);

  const onClickAccount = useCallback(() => {
    portalConnect?.accountManagement();
  }, [portalConnect]);

  const userName = useMemo(() => {
    if (!userInfo?.name || !accessToken) {
      return;
    }
    const parts = (userInfo.name as string).split(" ");
    if (parts.length > 1) {
      return `${parts[0]} ${parts[parts.length - 1]}`;
    }

    return userInfo.name;
  }, [userInfo?.name, accessToken]);

  useEffect(() => {
    if (!!accessToken) {
      setLoggedIn(true);
      return;
    }
    setLoggedIn(false);
  }, [accessToken, userInfo]);

  const props: LoginAreaProps = {
    isLoggedIn,
    userName,
    onClickDashboard,
    onClickLogin,
    onClickAccount,
    onClickLogout,
  };

  return createElement(LoginArea, props);
};
