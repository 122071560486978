import React, { FC } from "react";
import { FooterContainer } from "../modules/footer/footer.container";
import { DepartmentContainer } from "../modules/department/department.container";
import { HeaderGenericContainer } from "../modules/header-generic/header-generic.container";
import { SearchBarContainer } from "../components/SearchBar/SearchBar.container";
import { LoginButtonContainer } from "../components/login-button/LoginButtonContainer";

export const Department: FC = () => {
  return (
    <div>
      <HeaderGenericContainer
        centerElement={<SearchBarContainer />}
        rightElement={<LoginButtonContainer />}
      />
      <DepartmentContainer />
      <FooterContainer />
    </div>
  );
};
