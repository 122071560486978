import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiUrl, getDadosAbertos } from "../../apis/open-data";
import { Box, Button, Container, Link, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import "./openData.css";
import { json2xml } from "xml-js";
import { openData } from "../openData-list/openData-list.container";
import { ServicoOpenDataProps } from "../openData-list/openData-list";

export const OpenDataContainer: FC = () => {
  const [response, setResponse] = useState({});
  const [matchedServic, setMatchedServico] =
    useState<ServicoOpenDataProps | null>(null);
  const [notification, setNotification] = useState<{
    message: string;
    severity: "success" | "error";
  } | null>(null);

  let { resources, category, query } =
    useParams<{ resources: string; category: string; query: string }>();

  const fetchAllPosts = async () => {
    getDadosAbertos(resources, category, query)
      .then((response) => setResponse(response))
      .catch((err) => {
        let errorMessage =
          "Não foi possível completar a ação! Tente novamente.";

        if (err?.response?.data?.status === 404) {
          errorMessage = "Recurso não encontrado!";
        }
        if (err?.response?.data?.status === 400) {
          errorMessage =
            err?.response?.data?.message ?? "Erro nos dados enviados!";
        }

        setNotification({ message: errorMessage, severity: "error" });
      });
    filterOpenData();
  };

  const filterOpenData = async () => {
    const matchedService = openData.find((item) => {
      return item.servicos.some((servico) => {
        const slug = servico.slug;
        if (resources) {
          if (category) {
            if (query) {
              return slug === `${resources}/${category}/${query}`;
            }
            return slug === `${resources}/${category}`;
          }
          return slug === `${resources}`;
        }
      });
    });

    if (matchedService) {
      const matchedServico = matchedService.servicos.find((servico) => {
        if (resources) {
          if (category) {
            if (query) {
              return servico.slug === `${resources}/${category}/${query}`;
            }
            return servico.slug === `${resources}/${category}`;
          }
          return servico.slug === `${resources}`;
        }
      });
      setMatchedServico(matchedServico || null);
    }
  };

  function convertToCSV(arr: any) {
    const array = [Object.keys(arr[0])].concat(arr);

    return array
      .map((it) => {
        return Object.values(it).toString();
      })
      .join("\n");
  }

  function downloadFile(content: any, fileName: string, contentType: string) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  function onDownload(format: "json" | "csv" | "xml") {
    const jsonFile = JSON.stringify(response, null, 2);
    if (format === "json") {
      downloadFile(jsonFile, "export.json", "application/json");
    } else if (format === "csv") {
      const csvFile = convertToCSV(response);
      downloadFile(csvFile, "export.csv", "text/csv;charset=UTF-8");
    } else if (format === "xml") {
      const xmlFile = json2xml(jsonFile, { compact: true, spaces: 1 });
      downloadFile(xmlFile, "export.xml", "application/xml");
    }
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchAllPosts();
  }, []);

  const Profile = () => (
    <pre style={{ height: "100%", maxHeight: 500, margin: "0 auto" }}>
      <code id="code-view">{JSON.stringify({ response }, null, 2)}</code>
    </pre>
  );

  return (
    <Box>
      <Container maxWidth="lg" sx={{ padding: 2 }}>
        <Box className="MuiBox_Content">
          <Box sx={{ flexDirection: "column" }}>
            <Typography className="MuiTypography_Content">
              {matchedServic?.content}
            </Typography>
            <Typography className="MuiTypography_URL">
              URL:
              <Link
                href={`${apiUrl}/${matchedServic?.slug}`}
                sx={{ ml: "0.5rem", color: "#206b82", lineBreak: "anywhere" }}
              >{`${apiUrl}/${matchedServic?.slug}`}</Link>
            </Typography>
            <Typography className="MuiTypography_Description">
              {matchedServic?.description}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              mb: "0.5rem",
              height: "35px",
              alignSelf: "end",
            }}
          >
            <Button className="button_mui" onClick={() => onDownload("json")}>
              JSON
            </Button>
            <Button
              className="button_mui"
              onClick={() => onDownload("csv")}
              sx={{ ml: "0.5rem" }}
            >
              CSV
            </Button>
            <Button
              className="button_mui"
              onClick={() => onDownload("xml")}
              sx={{ ml: "0.5rem" }}
            >
              XML
            </Button>
          </Box>
        </Box>
        <Profile />
      </Container>
      {Object.keys(response).length === 0 && (
        <Alert severity={notification?.severity} className="MuiAlert_openData">
          {notification?.message}
        </Alert>
      )}
    </Box>
  );
};
