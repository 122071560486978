import React, { FC, useState } from "react";
import { Container } from "../../components/container/container";
import { IDepartment } from "../../apis/department";
import { SearchInput } from "../../components/search-input/search-input";
import StarIcon from "@mui/icons-material/Star";
import "./department-list.css";

export interface DepartmentProps {
  departmentList?: IDepartment[];
  onClick: (slug: string) => void;
  onClickSearch: (text?: string) => void;
}

export const DepartmentList: FC<DepartmentProps> = ({
  onClick,
  departmentList,
  onClickSearch,
}) => {
  const [filter, setFilter] = useState<string>();

  return (
    <div className="xvia-list">
      <div className={"xvia-new-list__head_content"}>
        <Container className={"xvia-new-list__head_info_container"}>
          <h2>
            Início <span>/ Secretarias de Governo</span>
          </h2>
          <div className="xvia-new-list__head-title">
            <img
              className={"xvia-new-list__head-img"}
              src={`${process.env.PUBLIC_URL}/images/svgs/assured_workload.svg`}
              alt={"Icone Secretarias de Governo"}
            />
            Secretarias de Governo
          </div>
        </Container>
      </div>
      <Container className="xvia-list__container">
        <SearchInput
          className="xvia-home-search__input"
          placeholder="Filtrar"
          onClickSearch={() => {
            onClickSearch(filter);
          }}
          onChangeText={(value) => {
            onClickSearch(value);
            setFilter(value);
          }}
        />
        <div className="xvia-department-list__wrapp-card">
          {departmentList?.map((department) => {
            return (
              <div>
                <div
                  className="xvia-department-list__card"
                  onClick={() => onClick(department.slug)}
                >
                  <div>
                    <StarIcon className="xvia-department-list__card-icon" />
                  </div>
                  <div>
                    <h2>{department.shortName}</h2>
                    <h3>{department.name}</h3>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {!departmentList?.length && (
          <div style={{ width: "100%" }}>Nenhuma secretaria encontrada</div>
        )}
      </Container>
    </div>
  );
};
