import { FC } from "react";
import { ExternalLink } from "../Link/ExternalLink";
import { Container } from "@mui/material";
import "./top-bar.css";

export const TopBar: FC = () => {
  return (
    <div className="xvia-header-topbar">
      <Container className="xvia-content-section xvia-header-section">
        <div className="xvia-header-info">
          <ExternalLink link="https://transparencia.pi.gov.br/">
            Portal da transparência
          </ExternalLink>

          <ExternalLink link="https://www.pi.gov.br">Governo</ExternalLink>
        </div>
      </Container>
    </div>
  );
};
