import React, { useCallback, KeyboardEvent } from "react";
import { InputBase, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

export interface SearchBarProps {
  className?: string;
  onTextChange?: (text: string) => void;
  onGroupChange?: (key: string) => void;
  placeholderText?: string;
  onClickSearch?: () => void;
  selectedGroup?: string;
  groups?: { key: string; value: string }[];
}

export const SearchBar = ({
  className,
  onTextChange,
  onClickSearch,
  selectedGroup,
  onGroupChange,
  placeholderText,
  groups,
}: SearchBarProps) => {
  const baseClassName = cx("xvia-search-bar", className);

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onTextChange && onTextChange(event.target.value);
    },
    [onTextChange]
  );

  const onGroupChangeHandler = useCallback(
    (event: SelectChangeEvent) => {
      onGroupChange && onGroupChange(event.target.value);
    },
    [onGroupChange]
  );

  const onKeyDownEventHandler = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onClickSearch && onClickSearch();
      }
    },
    [onClickSearch]
  );

  return (
    <div className={baseClassName}>
      {groups?.length ? (
        <Select
          className="xvia-search-bar__select"
          value={selectedGroup}
          onChange={onGroupChangeHandler}
          autoWidth
          inputProps={{
            className: "xvia-search-bar__select-input",
          }}
        >
          {groups?.map((group) => (
            <MenuItem key={group.key} value={group.key}>
              {group.value}
            </MenuItem>
          ))}
        </Select>
      ) : null}
      <InputBase
        className="xvia-search-bar__input"
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholderText}
        inputProps={{ "aria-label": placeholderText }}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownEventHandler}
      />
      <button className={"xvia-search-bar__btn"} onClick={onClickSearch}>
        <FontAwesomeIcon icon={faSearch} />{" "}
        <span className="xvia-search-bar__btn-text">Buscar</span>
      </button>
    </div>
  );
};
