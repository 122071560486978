import axios from "axios";
import { Catalog } from "./search-catalog";

const apiUrl = process.env.BACKEND_ENDPOINT || "";
const axiosClient = axios.create({
  baseURL: `${apiUrl}`,
  timeout: 0,
  headers: {},
});
export interface IDepartment {
  id: string;
  slug: string;
  name: string;
  shortName: string;
  mission: string;
  vision: string;
  values: string;
  hidden: boolean;
  active: boolean;
  link?: string;
}

export interface ISection {
  id: string;
  name: string;
  address: string;
  postalCode: string;
  district: string;
  city: string;
  state: string;
  countryCode: string;
  active: boolean;
  openingTime: string;
  mapUrl: string;
}
export async function getDepartments(): Promise<IDepartment[]> {
  const response = await axiosClient.get(`/v1/department`);

  return response.data;
}
export async function getSection(departmentId?: string): Promise<ISection[]> {
  const response = await axiosClient.get(
    `/v1/department/${departmentId}/section`
  );

  return response.data;
}
export async function getSectionCatalog(
  departmentId?: string,
  sectionId?: string
): Promise<Catalog[]> {
  const response = await axiosClient.get(
    `/v1/department/${departmentId}/section/${sectionId}/catalogs`
  );
  return response.data;
}
