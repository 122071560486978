import React, { useCallback, useState } from "react";
import { Search, SearchProps } from "./search";
import { useHistory } from "react-router-dom";

interface SearchContainerProps {
  className?: string;
}

export const SearchContainer = ({ className }: SearchContainerProps) => {
  const [searchValue, setSearchValue] = useState<string>();
  let history = useHistory();

  const onClickSearch = useCallback(() => {
    if (!searchValue) return;
    history.push(`search/${searchValue}`);
  }, [searchValue, history]);

  const props: SearchProps = {
    onChangeText: setSearchValue,
    onClickSearch,
    className,
  };

  return <Search {...props} />;
};
