import { createContext, useCallback, useState } from "react";

type CategoryBannerContextType = {
  isOpen: boolean;
  onClickHeader: () => void;
};

export const CategoryBannerContext = createContext<CategoryBannerContextType>({
  isOpen: false,
  onClickHeader: () => {},
});

export const CategoryBannerContextProvider: React.FC = ({ children }) => {
  const [isOpen, setOpen] = useState(false);

  const onClickHeader = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen, setOpen]);

  return (
    <CategoryBannerContext.Provider value={{ isOpen, onClickHeader }}>
      {children}
    </CategoryBannerContext.Provider>
  );
};
