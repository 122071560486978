import React, { FC, MouseEventHandler } from "react";
import { Icon } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

import "./ombudsman-card.css";

export interface OmbudsmanCardProps {
  icon: SvgIconComponent;
  detail: string;
  onClick?: MouseEventHandler;
}

export const OmbudsmanCard: FC<OmbudsmanCardProps> = ({
  icon,
  detail,
  onClick,
}) => {
  return (
    <div className="xvia-ombudsman-card" onClick={onClick}>
      <Icon className="xvia-ombudsman-card__icon" component={icon} />
      <div>
        <p className="xvia-ombudsman-card__text">{detail}</p>
      </div>
    </div>
  );
};
