import React, { FC } from "react";
import { FooterContainer } from "../modules/footer/footer.container";
import { HeaderGenericContainer } from "../modules/header-generic/header-generic.container";
import { SearchBarContainer } from "../components/SearchBar/SearchBar.container";
import { LoginButtonContainer } from "../components/login-button/LoginButtonContainer";
import { OpenDataContainer } from "../modules/openData/openData.container";

export const OpenData: FC = () => {
  return (
    <div>
      <HeaderGenericContainer
        centerElement={<SearchBarContainer />}
        rightElement={<LoginButtonContainer />}
      />
      <OpenDataContainer />
      <FooterContainer />
    </div>
  );
};
