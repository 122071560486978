import React, {
  ChangeEventHandler,
  DetailedHTMLProps,
  FC,
  KeyboardEventHandler,
  useCallback,
} from "react";
import classNames from "classnames";
import "./input.css";

export interface InputProps
  extends DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onTextChange: (text: string) => void;
  onEnterPress?: () => void;
}

export const Input: FC<InputProps> = ({
  className,
  value,
  onTextChange,
  disabled,
  onChange,
  onEnterPress,
  ...props
}) => {
  const inputClassName = classNames({
    "xvia-form-input": true,
    "xvia-form-input--disabled": disabled,
    [className as string]: !!className,
  });

  const textChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const newValue = event.target.value;
      onTextChange(newValue);

      if (onChange) {
        onChange(event);
      }
    },
    [onChange, onTextChange]
  );

  const keyDownHandler = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === "Enter") {
        if (onEnterPress) onEnterPress();
      }
    },
    [onEnterPress]
  );

  return (
    <input
      value={value}
      disabled={disabled}
      className={inputClassName}
      onChange={textChangeHandler}
      onKeyDown={keyDownHandler}
      {...props}
    />
  );
};
