import { library } from "@fortawesome/fontawesome-svg-core";

import { fas } from "@fortawesome/free-solid-svg-icons";

import {
  faInstagram,
  faFacebookF,
  faYoutube,
  faAndroid,
  faApple,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  fas,
  faInstagram,
  faFacebookF,
  faYoutube,
  faTwitter,
  faAndroid,
  faApple
);
