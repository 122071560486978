import React, { useCallback } from "react";
import "./login-area.css";
import { Button } from "../../components/button/button";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LogoutIcon from "@mui/icons-material/Logout";

export interface LoginAreaProps {
  isLoggedIn: boolean;
  userName?: string;
  onClickDashboard: () => void;
  onClickLogin: () => void;
  onClickAccount: () => void;
  onClickLogout: () => void;
}
export const LoginArea = ({
  isLoggedIn,
  onClickDashboard,
  onClickLogin,
  onClickAccount,
  onClickLogout,
  userName,
}: LoginAreaProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickLogin = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!isLoggedIn) {
        onClickLogin();
        return;
      }
      setAnchorEl(event.currentTarget);
    },
    [isLoggedIn, setAnchorEl, onClickLogin]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div className="xvia-login-area">
      {isLoggedIn ? (
        <Tooltip title="Meu painel">
          <Button
            icon={"fa-house"}
            type={"secondary"}
            title={""}
            className="xvia-login-area__home-button"
            onClick={onClickDashboard}
          />
        </Tooltip>
      ) : null}
      <Button
        icon={isLoggedIn ? "fa-bars" : "fa-user"}
        type={"secondary"}
        title={isLoggedIn ? (userName as string) : "ACESSO CIDADÃO"}
        className="xvia-login-area__login-button"
        onClick={handleClickLogin}
      />
      <Menu
        id="login-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={onClickAccount}>
          <ListItemIcon>
            <PersonOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Minha Conta</ListItemText>
        </MenuItem>
        <MenuItem onClick={onClickLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};
