import React, { FC } from "react";
import { FooterContainer } from "../modules/footer/footer.container";
import { DepartmentListContainer } from "../modules/department-list/department-list.container";
import { HeaderGenericContainer } from "../modules/header-generic/header-generic.container";
import { SearchBarContainer } from "../components/SearchBar/SearchBar.container";
import { LoginButtonContainer } from "../components/login-button/LoginButtonContainer";

export const DepartmentList: FC = () => {
  return (
    <div>
      <HeaderGenericContainer
        centerElement={<SearchBarContainer />}
        rightElement={<LoginButtonContainer />}
      />
      <div>
        <DepartmentListContainer />
      </div>
      <div>
        <FooterContainer />
      </div>
    </div>
  );
};
