import React, { FC, MouseEventHandler } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Typography } from "@mui/material";
import { ServiceLevelBadge } from "../../service-level-badge/service-level-badge";

import "./service-card.css";

export interface ServiceCardProps {
  title: string;
  description: string;
  categoryIcon: string;
  rating?: number;
  imageUrl?: string;
  isOnline?: boolean;
  focused?: boolean;
  isFavourite?: boolean;
  type?: "online" | "in-person";
  targets?: string[];
  onClick: any;
  onClickFavourite?: MouseEventHandler;
  accessProfile?: string;
}

export const ServiceCard: FC<ServiceCardProps> = ({
  title,
  focused,
  imageUrl,
  categoryIcon,
  onClick,
  description,
  accessProfile,
}) => {
  const containerClassName = classNames({
    "xvia-service-card": true,
    "xvia-service-card---focused": focused,
  });

  return (
    <button aria-label={title} onClick={onClick} className={containerClassName}>
      {imageUrl ? (
        <div className="xvia-service-card__thumb">
          <img src={imageUrl} alt="thumb" />
        </div>
      ) : null}
      <div className="xvia-service-card__content">
        <div className="xvia-service-card__header">
          <div className="xvia-service-card__icon">
            <FontAwesomeIcon icon={`${categoryIcon}` as IconProp} />
          </div>

          {!!accessProfile && (
            <div className="xvia-service-card__badge">
              <ServiceLevelBadge level={accessProfile} />
            </div>
          )}
        </div>

        <div className="xvia-service-card__title-wrapper">
          <Typography variant="h5" className="xvia-service-card__title">
            {title}
          </Typography>
        </div>
        <Typography variant="body1" className="xvia-service-card__sub-title">
          {description}
        </Typography>
      </div>
    </button>
  );
};
