import React, { FC } from "react";

import "./link-list.css";

export interface LinkListProps {
  title: string;
  linkList: { text: string; link: string }[];
}

export const LinkList: FC<LinkListProps> = ({ title, linkList }) => {
  return (
    <span className={"xvia-link-list"}>
      <span className="xvia-link-list__title">{title}</span>
      <div>
        <ul className={"xvia-link-list__ul"}>
          {linkList.map((link) => {
            return (
              <li key={link.link}>
                <a
                  href={link.link}
                  target={"_blank"}
                  className={"xvia-link-list__text"}
                  rel="noreferrer"
                >
                  {link.text}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </span>
  );
};
