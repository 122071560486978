import { createElement, useEffect } from "react";
import { HeaderGeneric, HeaderProps } from "./header-generic";

export const HeaderGenericContainer = (props: HeaderProps) => {
  useEffect(() => {
    const portalHeader = document.getElementsByClassName(
      "xvia-header-content"
    )?.[0] as HTMLDivElement;

    if (!portalHeader?.style) {
      return () => null;
    }

    portalHeader.style.display = "none";
    return () => {
      portalHeader.style.display = "flex";
    };
  }, []);

  return createElement(HeaderGeneric, props);
};
