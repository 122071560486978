import React, { MouseEventHandler } from "react";
import { SearchInput } from "../../components/search-input/search-input";
import "./search.css";
import classNames from "classnames";

export interface SearchProps {
  value?: string;
  onClickSearch: MouseEventHandler;
  onChangeText: (text: string) => void;
  className?: string;
}
export const Search = ({
  onChangeText,
  onClickSearch,
  value,
  className,
}: SearchProps) => {
  return (
    <div
      className={classNames({
        "xvia-home-search__input-wrapper": true,
        [className as string]: !!className,
      })}
    >
      <SearchInput
        className="xvia-home-search__input"
        placeholder="Qual serviço você procura?"
        onClickSearch={onClickSearch}
        onChangeText={onChangeText}
        value={value}
      />
    </div>
  );
};
