let Parser = require("rss-parser");
let parser = new Parser({
  requestOptions: {
    rejectUnauthorized: false,
  },
});

export interface News {
  title: string;
  imageUrl: string;
  categoryName: string;
  link: string;
}

const apiUrl = process.env.BACKEND_ENDPOINT || "";

export async function getNews(): Promise<News[]> {
  let feed = await parser.parseURL(`${apiUrl}/api/rss-api`);
  feed = feed.items?.slice(0, 6);
  feed = feed.map((news: any) => {
    return {
      title: news?.title,
      imageUrl: news?.enclosure?.url,
      categoryName: news?.categories?.join(" | "),
      link: news?.guid,
    };
  });
  return feed;
}
