import React, { FC } from "react";

import { AppCardProps } from "../../components/cards/app-card/app-card";

import "./apps.css";

export interface AppsProps {
  apps: AppCardProps[];
}

export const Apps: FC<AppsProps> = ({ apps }) => {
  return <div></div>;
};
