import React, { FC, useCallback, useEffect, useState } from "react";
import { getServerData } from "../../util/get-server-data";
import { useHistory } from "react-router-dom";
import { getDepartments, IDepartment } from "../../apis/department";
import { DepartmentList, DepartmentProps } from "./department-list";

export const DepartmentListContainer: FC = () => {
  let history = useHistory();

  const [isMounted, setIsMounted] = useState(false);

  const [departmentList, setDepartmentList] = useState<IDepartment[]>([]);
  const [departmentListFiltered, setDepartmentListFiltered] = useState<
    IDepartment[]
  >([]);

  const onClickCard = useCallback(
    (slug) => {
      history.push(`/orgao/${slug}`);
    },
    [history]
  );

  const onClickSearch = useCallback(
    (filter) => {
      const filtered = departmentList.filter(
        ({ shortName }) =>
          shortName?.toUpperCase()?.indexOf(filter?.toUpperCase()) !== -1
      );

      setDepartmentListFiltered(filtered);
    },
    [departmentList]
  );

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);

      const parsedData = getServerData("departmentList", []);

      if (parsedData?.length === 0) {
        getDepartments()
          .then((result) => {
            setDepartmentList(result);
            setDepartmentListFiltered(result);
          })
          .catch((err) => {
            console.error("Error fetching department list:", err);
          });
      } else {
        setDepartmentList(parsedData);
      }
    }
  }, [isMounted]);

  const newsProps: DepartmentProps = {
    onClick: onClickCard,
    departmentList: departmentListFiltered,
    onClickSearch,
  };

  return <DepartmentList {...newsProps} />;
};
