import React, { PropsWithChildren } from "react";
import "./main-banner.css";
import { Container } from "@mui/material";

export interface MainBannerProps {
  imageBanner: string;
}
export const MainBanner = ({
  imageBanner,
  children,
}: PropsWithChildren<MainBannerProps>) => {
  return (
    <div
      className="xvia-main-banner"
      style={{
        backgroundImage: `url("${imageBanner}")`,
      }}
    >
      <div className="xvia-main-banner__content-wrapper">
        <Container className="xvia-main-banner__content">{children}</Container>
      </div>
    </div>
  );
};
