import React, { FC, MouseEventHandler, useMemo } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LogoutIcon from "@mui/icons-material/Logout";

export interface LoginButtonProps {
  onClickLogin: () => void;
  onClickLogout: () => void;
  onAccountManagementRequest: MouseEventHandler<HTMLLIElement>;
  onNotificationsRequest: MouseEventHandler<HTMLLIElement>;
  onDashboardRequest: MouseEventHandler<HTMLLIElement>;
  userInfo?: Record<string, unknown>;
  loading?: boolean;
  isMobile?: boolean;
}

export const LoginButton: FC<LoginButtonProps> = ({
  onClickLogin,
  onClickLogout,
  onAccountManagementRequest,
  onNotificationsRequest,
  onDashboardRequest,
  userInfo,
  loading,
  isMobile,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const baseClassName = classnames({
    "xvia-header-btn": true,
    "xvia-header-btn__loading": !userInfo && loading,
    "xvia-header-btn__inactive": !!userInfo,
  });

  const menu = (
    <Menu
      id="user-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {!!userInfo ? (
        <div>
          <MenuItem key="user-account" onClick={onDashboardRequest}>
            <ListItemIcon>
              <HomeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </MenuItem>
          <MenuItem key="user-account" onClick={onAccountManagementRequest}>
            <ListItemIcon>
              <PersonOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Minha Conta</ListItemText>
          </MenuItem>
          <MenuItem
            key="user-account"
            onClick={onNotificationsRequest}
            style={{ display: "none" }}
          >
            Notificações
          </MenuItem>
          <MenuItem key="logout" onClick={onClickLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Sair</ListItemText>
          </MenuItem>
        </div>
      ) : (
        <MenuItem key="user-account" onClick={onClickLogin}>
          Entrar
        </MenuItem>
      )}
    </Menu>
  );

  const name = useMemo(() => {
    if (!userInfo?.name) {
      return;
    }
    const parts = (userInfo.name as string).split(" ");
    if (parts.length > 1) {
      return `${parts[0]} ${parts[parts.length - 1]}`;
    }

    return userInfo.name;
  }, [userInfo?.name]);

  if (isMobile) {
    return (
      <div>
        <LoadingButton
          loading={loading}
          className={baseClassName}
          onClick={handleClick}
        >
          {loading ? null : <FontAwesomeIcon icon={faBars} />}
        </LoadingButton>
        {menu}
      </div>
    );
  }

  return (
    <div className={baseClassName}>
      {!userInfo ? (
        <button onClick={loading ? undefined : onClickLogin}>
          <span className={"xvia-header-btn--icon"}>
            <FontAwesomeIcon icon={faUser} />
          </span>{" "}
          ACESSO CIDADÃO
        </button>
      ) : (
        <div>
          <button
            onClick={handleClick}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {name || ""} <ExpandMoreIcon fontSize="large" />
          </button>
          {menu}
        </div>
      )}
    </div>
  );
};
