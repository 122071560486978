import React, { FC } from "react";
import classNames from "classnames";

export interface TitleProps {
  level: number;
  noMargin?: boolean;
  className?: string;
  backgroundColor?: string;
}

export const Title: FC<TitleProps> = ({
  level,
  className,
  children,
  backgroundColor = "",
  ...props
}) => {
  const titleClassName = classNames({
    "xvia-title-h": true,
    [`xvia-title-h${level}`]: true,
    [`xvia-title--no-margin`]: true,
    [className || ""]: !!className,
  });

  switch (level) {
    case 1:
    default:
      return (
        <h1 className={titleClassName} style={{ backgroundColor }} {...props}>
          {children}
        </h1>
      );
    case 2:
      return (
        <h2 className={titleClassName} style={{ backgroundColor }} {...props}>
          {children}
        </h2>
      );
    case 3:
      return (
        <h3 className={titleClassName} style={{ backgroundColor }} {...props}>
          {children}
        </h3>
      );
    case 4:
      return (
        <h4 className={titleClassName} style={{ backgroundColor }} {...props}>
          {children}
        </h4>
      );
    case 5:
      return (
        <h5 className={titleClassName} style={{ backgroundColor }} {...props}>
          {children}
        </h5>
      );
  }
};
