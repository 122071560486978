import React, {
  ChangeEventHandler,
  DetailedHTMLProps,
  FC,
  KeyboardEventHandler,
  useCallback,
  useMemo,
} from "react";
import classNames from "classnames";
import { Button } from "../button/button";
import { Col, Row } from "antd";
import "./search-input.css";

export interface SearchInputProps
  extends DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  onClickSearch: any;
  onChangeText: (text: string) => void;
}

export const SearchInput: FC<SearchInputProps> = ({
  className,
  onChangeText,
  onClickSearch,
  value,
  ...inputProps
}) => {
  const wrapperClassName = useMemo(
    () =>
      classNames({
        "xvia-search-input": true,
        [className as string]: !!className,
      }),
    [className]
  );

  const textChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const newValue = event.target.value;

      if (value !== newValue) {
        onChangeText(newValue);
      }
    },
    [onChangeText, value]
  );

  const keyDownHandler = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === "Enter") {
        onClickSearch(event);
      }
    },
    [onClickSearch]
  );

  return (
    <div className={wrapperClassName}>
      <Row align="middle" justify="space-between">
        <Col flex="auto">
          <input
            onChange={textChangeHandler}
            onKeyDown={keyDownHandler}
            className="xvia-search-input__input"
            value={value}
            type="text"
            name="keyword"
            {...inputProps}
          />
        </Col>
        <Col className="xvia-search-input__button-wrapper">
          <Button
            title="Buscar"
            icon="fa-magnifying-glass"
            className="xvia-search-input__button"
            onClick={onClickSearch}
          />
        </Col>
      </Row>
    </div>
  );
};
