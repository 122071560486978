import React, { FC, useState } from "react";
import { Container } from "../../components/container/container";
import { SearchInput } from "../../components/search-input/search-input";
import { SvgIconComponent } from "@mui/icons-material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Icon,
  Typography,
} from "@mui/material";
import "./openData-list.css";

export interface ServicoOpenDataProps {
  content: string;
  description: string;
  slug: string;
}

export interface OpenDataItensProps {
  text: string;
  icon: SvgIconComponent;
  servicos: ServicoOpenDataProps[];
}

export interface DadosAbertosProps {
  onClick: (slug: string) => void;
  onClickSearch: (text?: string) => void;
  itens?: OpenDataItensProps[];
}

export const DadosAbertosList: FC<DadosAbertosProps> = ({
  onClick,
  itens,
  onClickSearch,
}) => {
  const [filter, setFilter] = useState<string>();

  return (
    <div className="xvia-list">
      <div className={"xvia-new-list__head_content"}>
        <Container className={"xvia-new-list__head_info_container"}>
          <h2>
            Início <span>/ Dados Abertos</span>
          </h2>
          <div className="xvia-new-list__head-title">
            <img
              className={"xvia-new-list__head-img"}
              src={`${process.env.PUBLIC_URL}/images/svgs/assured_workload.svg`}
              alt={"Icone Secretarias de Governo"}
            />
            Dados Abertos
          </div>
        </Container>
      </div>
      <Container className="xvia-list__container">
        <SearchInput
          className="xvia-home-search__input"
          placeholder="Filtrar"
          onClickSearch={() => {
            onClickSearch(filter);
          }}
          onChangeText={(value) => {
            onClickSearch(value);
            setFilter(value);
          }}
        />
        <Box>
          <Typography className="xvia-openData-content_title">
            O que é esse serviço?
          </Typography>
          <Typography className="xvia-openData-content_Description">
            O Portal de Dados Abertos do Estado do Piauí, uma iniciativa
            dedicada à transparência, colaboração e inovação, disponibiliza
            informações governamentais de forma acessível a todos os cidadãos
            interessados em utilizar, reutilizar, redistribuir ou buscar
            inspiração para criar conteúdo e aplicações. Esses dados são
            apresentados em formatos técnicos não proprietários, facilitando sua
            compreensão e manipulação, e estão sujeitos a licenças que promovem
            a livre reutilização, sendo necessário apenas creditar a fonte.
          </Typography>
        </Box>
        <div className="xvia-openData-list_wrapp-card">
          {itens?.map((i) => {
            return (
              <Accordion className="xvia-openData-list_card" key={i.text}>
                <AccordionSummary
                  className="xvia-MuiAccordionSummary"
                  expandIcon={
                    <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
                  }
                  sx={{ flexDirection: "row-reverse" }}
                >
                  {i.text}
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: 1,
                    borderTop: "1px solid rgba(0, 0, 0, .125)",
                    color: "rgb(133, 142, 173)",
                  }}
                >
                  {i.servicos.map((servicos) => (
                    <Typography
                      key={servicos.content}
                      onClick={() => onClick(servicos.slug)}
                      sx={{
                        padding: "6px",
                        cursor: "pointer",
                      }}
                    >
                      {servicos.content}
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>

        {!itens?.length && (
          <div style={{ width: "100%" }}>Nenhum dado encontrado</div>
        )}
      </Container>
    </div>
  );
};
