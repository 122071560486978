import { FC, useMemo } from "react";
import { Chip } from "@mui/material";
import classNames from "classnames";

import "./service-level-badge.css";

enum ServiceLevel {
  BRONZE = "BRONZE",
  PRATA = "PRATA",
  OURO = "OURO",
  OPALA = "OPALA",
}

export interface ServiceLevelBadgeProps {
  level: string;
  size?: "small" | "medium";
}

export const ServiceLevelBadge: FC<ServiceLevelBadgeProps> = ({
  level,
  size = "small",
}) => {
  const chipClassnames = useMemo(() => {
    const cn = ["xvia-service-level-badge"];
    const hasModifier = Object.keys(ServiceLevel).includes(level.toUpperCase());

    if (hasModifier) {
      cn.push(`xvia-service-level-badge--${level.toLowerCase()}`);
    }

    return classNames(cn);
  }, [level]);

  return <Chip label={level} size={size} className={chipClassnames} />;
};
