import React, { FC, useCallback, useEffect, useState } from "react";
import { NewsList, NewsProps } from "./news-list";
import { getNews } from "../../apis/news-rss";
import { useWebgate } from "@xvia/webgate-connect";
import { getServerData } from "../../util/get-server-data";

export const NewsListContainer: FC = () => {
  const { serverData } = useWebgate();
  const [newsList, setNewsList] = useState<any[]>(
    serverData?.news || getServerData("news", null)
  );

  const getServerNews = useCallback(() => {
    getNews()
      .then((result) => {
        setNewsList(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!newsList?.length) getServerNews();
  }, [getServerNews, newsList?.length]);

  const newsProps: NewsProps = {
    newsList,
    onSeeMoreClick: () => {
      window.open("http://www.pi.gov.br/noticias", "_blank");
    },
    onNewsClick: (news) => {
      window.open(news.link, "_blank");
    },
  };

  return <NewsList {...newsProps} />;
};
