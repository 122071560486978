import React, { FC } from "react";
import { Footer, FooterProps } from "./footer";

export const FooterContainer: FC = () => {
  const portalUrl = process.env.PORTAL_URL || "https://dev.pidigital.pi.gov.br";

  const props: FooterProps = {
    social: [
      {
        icon: "fa-facebook-f",
        link: "https://pt-br.facebook.com/governodopiaui/",
      },
      {
        icon: "fa-twitter",
        link: "https://twitter.com/GovernodoPi",
      },
      {
        icon: "fa-instagram",
        link: "https://instagram.com/governodopiaui",
      },
      {
        icon: "fa-youtube",
        link: "https://www.youtube.com/channel/UCUvn6mz9Su40_xqyZjK5Onw",
      },
    ],
    lists: [
      {
        title: "Links úteis",
        linkList: [
          {
            text: "Crie sua conta Gov.Pi",
            link: `${portalUrl}/app/dashboard`,
          },
          {
            text: "Política de Privacidade e Termos de Uso",
            link: `${portalUrl}/app/politicas-de-privacidade-termo-de-uso`,
          },
          {
            text: "Portal da Transparência",
            link: "https://transparencia.pi.gov.br",
          },
        ],
      },
      {
        title: "Governo",
        linkList: [
          {
            text: "Site Institucional",
            link: "https://www.pi.gov.br/",
          },
          {
            text: "Agenda do governador",
            link: "https://www.pi.gov.br/agenda/",
          },
          {
            text: "Ações",
            link: "https://www.pi.gov.br/",
          },
          {
            text: "Dados Abertos",
            link: "/app/catalog/dados",
          },
          {
            text: "Órgãos",
            link: "/app/catalog/orgao",
          },
        ],
      },
    ],
    contact: "  Contato: (86) 3326-2001  ",
    ramal: "  162",
    email: "  atendimento@ouvidoriageral.pi.gov.br",
    address:
      "Endereço: Av. Antonino Freire, 1450<br/>Centro (Sul)<br/>Teresina - PI, 64001-040",
  };

  return <Footer {...props} />;
};
