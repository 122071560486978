import React, { FC, useEffect, useState } from "react";
import { Container } from "../../components/container/container";
// @ts-ignore
import striptags from "striptags";
import { IDepartment, ISection } from "../../apis/department";
import { Catalog } from "../../apis/search-catalog";
import { ServiceCard } from "../../components/cards/service-card/service-card";
import { isValidUrl } from "../../util/url";
import "./department.css";
import { Carousel } from "../../components/carousel/carousel";
import { SectionTitle } from "../../components/section-title/section-title";

export interface DepartmentProps extends IDepartment {
  section?: ISection[];
  catalogList?: Catalog[];
  onClickCatalog: (slug: string, categorySlug: string) => void;
  currentPage?: number;
  resultTotal?: number;
  onPaginate?: (page: number) => void;
  departmentServicesRef?: React.Ref<HTMLDivElement>;
}

export const Department: FC<DepartmentProps> = ({
  name,
  shortName,
  mission,
  vision,
  values,
  slug,
  section,
  catalogList,
  onClickCatalog,
  link,
  currentPage,
  resultTotal,
  onPaginate,
  departmentServicesRef,
}) => {
  const [session, setSession] = useState<any>();
  useEffect(() => {
    if (section && section[0]) {
      setSession(section[0]);
    }
  }, [section]);
  return (
    <div className="xvia-department">
      <div className={"xvia-new-list__head_content"}>
        <Container className={"xvia-new-list__head_info_container"}>
          <h2>
            Início <span>/ Secretarias de Governo</span>
          </h2>
          <div className="xvia-new-list__head-title">
            <img
              className={"xvia-new-list__head-img"}
              src={`${process.env.PUBLIC_URL}/images/svgs/assured_workload.svg`}
              alt={"Icone Secretarias de Governo"}
            />
            {shortName}
          </div>
        </Container>
      </div>
      <Container className="xvia-department__container">
        <div className="xvia-department__wrapp-info">
          <div className="xvia-department__company-goals">
            <div className="xvia-department__info_section">
              <h2>Missão </h2>
              <span>{striptags(mission || "Não informado")}</span>
            </div>
            <div className="xvia-department__info_section">
              <h2>Visão </h2>
              <span>{striptags(vision || "Não informado")}</span>
            </div>
            <div className="xvia-department__info_section">
              <h2>Valores </h2>
              <span>{striptags(values || "Não informado")}</span>
            </div>
          </div>
          <div className="xvia-department__info">
            {isValidUrl(link) && (
              <div className="xvia-department__info-secretary">
                <h3>Site do órgão</h3>
                <a href={link} target="_blank" rel="noreferrer">
                  <p>{link}</p>
                </a>
              </div>
            )}
            {session && (
              <div className="xvia-department__info-secretary">
                <h3>Endereço</h3>
                <p>
                  Endereço: {session?.address} • {session?.district} •
                  {session?.city} - {session?.state} •{session?.openingTime}
                </p>
              </div>
            )}
          </div>
        </div>

        {!!catalogList?.length && (
          <div
            className="xvia-department__info_section"
            ref={departmentServicesRef}
          >
            <SectionTitle text={"Serviços prestados"} />
            <Carousel>
              {catalogList?.map((catalog) => (
                <ServiceCard
                  key={catalog.slug}
                  title={catalog.title}
                  description={catalog.description}
                  categoryIcon={catalog?.icon}
                  onClick={() => {
                    onClickCatalog(catalog.slug, catalog.categorySlug);
                  }}
                  type={catalog.isOnline ? "online" : "in-person"}
                  imageUrl={catalog?.imageUrl}
                  targets={catalog?.targets}
                  accessProfile={catalog?.accessProfile}
                />
              ))}
            </Carousel>
          </div>
        )}
      </Container>
    </div>
  );
};
