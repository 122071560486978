import axios from "axios";
import { Category } from "@xvia/webgate-connect";

const apiUrl = process.env.BACKEND_ENDPOINT || "";

const axiosClient = axios.create({
  baseURL: `${apiUrl}`,
  timeout: 0,
  headers: {},
});

export async function getCategories(): Promise<Category[]> {
  const response = await axiosClient.get(`/v1/category`);
  return response.data;
}
