import React, { FC, MouseEventHandler, useCallback, useMemo } from "react";
import { Typography, Button } from "@mui/material";
import { Info } from "../../info/info";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ServiceLevelBadge } from "../../service-level-badge/service-level-badge";

import "./listing-card.css";

export interface ListingCardProps {
  title: string;
  subtitle: string;
  focused?: boolean;
  isDigital?: boolean;
  cost?: string;
  disposition?: "line" | "card";
  onClick: MouseEventHandler;
  onClickOnlineService: MouseEventHandler;
  icon?: string;
  accessProfile?: string;
}

export const ListingCard: FC<ListingCardProps> = ({
  title,
  subtitle,
  focused,
  disposition,
  isDigital,
  cost,
  onClick,
  onClickOnlineService,
  icon,
  accessProfile,
}) => {
  const containerClassName = useMemo(() => {
    return classNames({
      "xvia-listing-card": true,
      "xvia-listing-card---focused": focused,
      "xvia-listing-card__line": disposition === "line",
    });
  }, [focused, disposition]);

  const onlineServiceClickHandler = useCallback<MouseEventHandler>(
    (event) => {
      event.stopPropagation();
      onClickOnlineService(event);
    },
    [onClickOnlineService]
  );

  return (
    <button aria-label={title} onClick={onClick} className={containerClassName}>
      <div className="xvia-listing-card__header">
        <div className="xvia-listing-card__header-icon">
          <FontAwesomeIcon icon={`fa-solid ${icon}` as IconProp} />
        </div>
        {!!accessProfile && (
          <div className="xvia-listing-card__header-badge">
            <ServiceLevelBadge level={accessProfile} />
          </div>
        )}
      </div>
      <div className="xvia-listing-card__content">
        <Typography variant="h5" className="xvia-listing-card__title">
          {title}
        </Typography>
        <Typography variant="body1" className="xvia-listing-card__subtitle">
          {subtitle}
        </Typography>
        <div className="xvia-listing-card__bottom">
          {cost ? (
            <Typography variant="body1" className="xvia-listing-card__info">
              <Info text={cost} icon="circle-dollar" />
            </Typography>
          ) : null}
          {isDigital && (
            <Button
              variant="text"
              onClick={onlineServiceClickHandler}
              className={"xvia-listing-card__bottom-button"}
              startIcon={
                <FontAwesomeIcon
                  onClick={onlineServiceClickHandler}
                  icon={`fa-solid fa-right-to-bracket` as IconProp}
                />
              }
            >
              Acessar Serviço Digital
            </Button>
          )}
        </div>
      </div>
    </button>
  );
};
