import { createElement, useEffect, useState } from "react";
import { Header, HeaderProps } from "./header";
import { SearchContainer } from "../search/search.container";
import { LoginAreaContainer } from "../login-area/login-area.container";

export const HeaderContainer = () => {
  const [showFloatingHeader, setShowFloatingHeader] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 350) {
        setShowFloatingHeader(true);
        return;
      }
      setShowFloatingHeader(false);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [setShowFloatingHeader]);

  useEffect(() => {
    const portalHeader = document.getElementsByClassName(
      "xvia-header-content"
    )?.[0] as HTMLDivElement;

    if (!portalHeader?.style) {
      return () => null;
    }

    portalHeader.style.display = "none";
    return () => {
      portalHeader.style.display = "flex";
    };
  }, []);

  const props: HeaderProps = {
    showFloatingHeader,
    SearchComponent: SearchContainer,
    LoginComponent: LoginAreaContainer,
  };

  return createElement(Header, props);
};
