import React, { FC, MouseEventHandler } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./social-network-card.css";

export interface SocialNetworkCardProps {
  icon: IconDefinition;
  detail: string;
  onClick?: MouseEventHandler;
}

export const SocialNetworkCard: FC<SocialNetworkCardProps> = ({
  icon,
  detail,
  onClick,
}) => {
  return (
    <div className="xvia-social-network-card" onClick={onClick}>
      <FontAwesomeIcon className="xvia-social-network-card__icon" icon={icon} />
      <div>
        <p className="xvia-social-network-card__text">{detail}</p>
      </div>
    </div>
  );
};
