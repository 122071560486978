import React, { FC, MouseEventHandler } from "react";
import { Typography, Grid, IconButton, Button } from "@mui/material";
import { Rating } from "../../rating/rating";
import { Info } from "../../info/info";

import "./catalog-card.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import { AccessProfile, ResultCategory } from "../../../apis/detail-catalog";

export interface CatalogCardProps {
  title?: string;
  rating?: number;
  totalRatings?: number;
  isFree?: boolean;
  isOnline?: boolean;
  isSelfService?: boolean;
  resultCategory?: ResultCategory;
  accessProfile?: AccessProfile;
  lastUpdate?: string;
  link?: string;
  onFavoriteClick?: MouseEventHandler;
  onPdfClick?: MouseEventHandler;
  onServiceClick?: MouseEventHandler;
  onRatingClick?: (rating: number) => void;
}

export const CatalogCard: FC<CatalogCardProps> = ({
  title,
  rating = 0,
  totalRatings = 0,
  isFree,
  isOnline,
  isSelfService,
  resultCategory,
  link,
  lastUpdate,
  onFavoriteClick,
  onPdfClick,
  onServiceClick,
  onRatingClick,
}) => {
  return (
    <div className={"xvia-catalog-card"}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={5} md={5} lg={5} xl={6.5}>
          <Typography className="xvia-catalog-card__title_text">
            {title}
          </Typography>
          <div className="xvia-catalog-card__subtitle_text">
            {resultCategory && <div>{resultCategory.name}</div>}
            {resultCategory && isSelfService && <div> - </div>}
            {isSelfService && <div>Autosserviço</div>}
          </div>
          <div className={"xvia-catalog-card__info_content"}>
            <span>
              <Rating
                value={rating}
                readOnly={false}
                onClickItem={onRatingClick}
              />
            </span>
            {!!lastUpdate && (
              <Info text={`Atualizado há ${lastUpdate}`} icon={"fa-clock"} />
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} xl={1.5}>
          <div className="xvia-catalog-card__info_content">
            {isOnline && (
              <div>
                <FontAwesomeIcon
                  icon={faEarthAmericas}
                  className="fa-light"
                  size="2xl"
                ></FontAwesomeIcon>
                <Info text={"Online"} />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} xl={1}>
          <div className="xvia-catalog-card__info_content">
            <IconButton
              aria-label="Favorite"
              onClick={onPdfClick}
              size="large"
              color="primary"
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                size="xl"
                style={{ color: "#ef4123" }}
              />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          {link && (
            <Button
              className="xvia-button xvia-button---primary"
              variant="contained"
              onClick={onServiceClick}
              startIcon={<FontAwesomeIcon icon={["fas", "right-to-bracket"]} />}
            >
              Acessar Serviço Digital
            </Button>
          )}
          {!link && (
            <Info
              text={"Serviço realizado presencialmente"}
              icon={"fa-location-dot"}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
