import axios from "axios";
import { Catalog } from "./search-catalog";

const apiUrl = process.env.BACKEND_ENDPOINT || "";
const axiosClient = axios.create({
  baseURL: `${apiUrl}`,
  timeout: 0,
  headers: {},
});
export async function getRecommendations(
  accessToken?: string
): Promise<Catalog[]> {
  const response = await axiosClient.get(
    `/api/recommendations/v1/catalog/recommendations`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}
