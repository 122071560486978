import axios from "axios";
import { Catalog } from "./search-catalog";

const apiUrl = process.env.BACKEND_ENDPOINT || "";
const axiosClient = axios.create({
  baseURL: `${apiUrl}`,
  timeout: 0,
  headers: {},
});
export async function getTarget(
  accessToken?: string,
  target?: string
): Promise<Catalog[]> {
  const response = await axiosClient.post(
    `/v1/search`,
    {
      groups: ["CATALOG"],
      targets: [target],
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}

export async function getRelevant(accessToken?: string): Promise<Catalog[]> {
  const response = await axiosClient.post(
    `/v1/search`,
    {
      groups: ["CATALOG"],
      highlighted: true,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}

export async function getTopCatalog(accessToken?: string): Promise<Catalog[]> {
  const response = await axiosClient.post(
    `/v1/search`,
    {
      top_hit: 10,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}
