import React, { FC } from "react";
import { Typography, Box } from "@mui/material";
import { Info } from "../../info/info";

import "./session-card.css";

export interface SessionCardProps {
  name?: string;
  address?: string;
  district?: string;
  city?: string;
  state?: string;
  openingTime?: string;
  mapUrl?: string;
}

export const SessionCard: FC<SessionCardProps> = ({
  name,
  address,
  district,
  city,
  state,
  openingTime,
  mapUrl,
}) => {
  return (
    <div>
      <div>
        {!name && (
          <Typography className={"xvia-card-section"}>
            Nenhuma unidade disponível para este serviço
          </Typography>
        )}
        {mapUrl && (
          <iframe
            src={mapUrl}
            title={"mapa"}
            className="xvia-card-section__map"
          />
        )}
        <Box
          sx={{ display: "flex", flexDirection: "column", paddingTop: "20px" }}
        >
          <Typography className={"xvia-card-section"}>{name}</Typography>
          <Info
            text={address + ", " + district + ", " + city + ", " + state}
            icon={"fa-location-dot"}
          />
          {openingTime && <Info text={openingTime} icon={"fa-clock"} />}
        </Box>
      </div>
    </div>
  );
};
