import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getServerData } from "../../util/get-server-data";
import { Category, useWebgate } from "@xvia/webgate-connect";
import { CategoryBanner, CategoryProps } from "./category-banner";
import { getCategories } from "../../apis/categories";
import { CategoryBannerContext } from "../../providers/category-banner-provider";

export const CategoryBannerContainer: FC = () => {
  const { serverData } = useWebgate();
  let history = useHistory();

  const { isOpen, onClickHeader } = useContext(CategoryBannerContext);
  const [categoryList, setCategoryList] = useState<Category[]>(
    serverData?.categoryList || getServerData("categoryList", null)
  );
  const getCategory = useCallback(() => {
    getCategories()
      .then((result) => {
        setCategoryList(result);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onClickCategory = useCallback(
    (category: Category) => {
      history.push(`/list/${category.slug}`);
    },
    [history]
  );

  useEffect(() => {
    if (!categoryList?.length) getCategory();
  }, [categoryList, getCategory]);

  const props: CategoryProps = {
    categoryList,
    onClickCategory,
    onClickHeader,
    isOpen,
  };

  return <CategoryBanner {...props} />;
};
