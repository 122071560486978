import React, { FC } from "react";
import { Element } from "react-scroll";
import { RecommendedServicesContainer } from "../modules/recommended-services/recommended-services.container";
import { RelevantServicesContainer } from "../modules/relevant-services/relevant-services.container";
import { NewsListContainer } from "../modules/news/news-list.container";
import { AppsContainer } from "../modules/apps/apps.container";
import { CategoryBannerContainer } from "../modules/category/category-banner.container";
import { FooterContainer } from "../modules/footer/footer.container";
import { TopServicesContainer } from "../modules/top-services/top-services.container";
import { SocialNetworkContainer } from "../modules/social-network/social-network.container";
import { MainBannerContainer } from "../modules/main-banner/main-banner.container";
import { HeaderContainer } from "../modules/header/header.container";
import { SearchContainer } from "../modules/search/search.container";
import { TopBar } from "../modules/top-bar/top-bar";
import { CategoryBannerContextProvider } from "../providers/category-banner-provider";
import { DownloadAppMobile } from "../modules/downloadAppMobile/downloadAppMobile";
import { OmbudsmanContainer } from "../modules/ombudsman/ombudsman.container";

export const Home: FC = () => {
  return (
    <div>
      <TopBar />
      <CategoryBannerContextProvider>
        <MainBannerContainer>
          <HeaderContainer />
          <SearchContainer className="xvia-home-search" />
        </MainBannerContainer>
        <Element name="recommendedServices">
          <RecommendedServicesContainer />
        </Element>
        <Element name="relevantServices">
          <RelevantServicesContainer />
        </Element>
        <Element name="topServices">
          <TopServicesContainer />
        </Element>
        <Element name="appsContainer">
          <AppsContainer />
        </Element>
        <Element name="category">
          <CategoryBannerContainer />
        </Element>
        <Element name="newsContainer">
          <NewsListContainer />
        </Element>
        <Element name="downloadAppMobile">
          <DownloadAppMobile />
        </Element>
        <Element name="ombudsman">
          <OmbudsmanContainer />
        </Element>
        <Element name="socialNetwork">
          <SocialNetworkContainer />
        </Element>
      </CategoryBannerContextProvider>
      <FooterContainer />
    </div>
  );
};
