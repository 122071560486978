import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useWebgate } from "@xvia/webgate-connect";
import { getServerData } from "../../util/get-server-data";

import { useHistory, useParams } from "react-router-dom";
import {
  getDepartments,
  getSection,
  IDepartment,
  ISection,
} from "../../apis/department";
import { Department, DepartmentProps } from "./department";
import { Catalog, search } from "../../apis/search-catalog";

export const DepartmentContainer: FC = () => {
  const { serverData } = useWebgate();
  let history = useHistory();
  let { orgao } = useParams<{ orgao: string }>();
  const ssrData =
    serverData?.departmentList || getServerData("departmentList", null);

  const [department, setDepartment] = useState<IDepartment | undefined>(
    ssrData?.find((i: IDepartment) => {
      return i.slug === orgao;
    })
  );

  const [section, setSection] = useState<ISection[]>();
  const [catalogList, setCatalogList] = useState<Catalog[]>();
  const [page, setPage] = useState(0);
  const [resultTotal, setResultTotal] = useState(0);
  const departmentServicesRef = useRef<HTMLDivElement>();

  const currentPage = useMemo(() => page + 1, [page]);
  const searchFrom = useMemo(() => page * 10, [page]);

  const serverSearch = useCallback((slug: string, from: number) => {
    search(undefined, undefined, undefined, slug, from)
      .then((res) => {
        setCatalogList(res);
        setResultTotal(res[0]?.resultTotal ?? 0);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getDepartment = useCallback(() => {
    getDepartments()
      .then((list) => {
        const department = list?.find((i) => {
          return i.slug === orgao;
        });
        setDepartment(department);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [orgao]);

  const getSectionServer = useCallback((departmentId) => {
    getSection(departmentId)
      .then(setSection)
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onClickCatalog = useCallback(
    (slug, categorySlug) => {
      history.push(`/${categorySlug}/${slug}`);
    },
    [history]
  );

  const onPaginate = useCallback((id: number) => {
    setPage(id - 1);
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!department) {
      getDepartment();
    }
  }, [department, getDepartment]);

  useEffect(() => {
    if (!section && department) {
      getSectionServer(department?.id);
    }
  }, [department, getSectionServer, section]);

  useEffect(() => {
    serverSearch(orgao, searchFrom);
  }, [orgao, searchFrom, serverSearch]);

  useEffect(() => {
    window.scrollTo({
      top: departmentServicesRef.current?.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }, [page]);

  const props: DepartmentProps = {
    ...department,
    section,
    catalogList,
    onClickCatalog,
    onPaginate,
    currentPage,
    resultTotal,
    departmentServicesRef,
  } as DepartmentProps;
  return <Department {...props} />;
};
