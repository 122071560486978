import React, { FC } from "react";

import { Container } from "../../components/container/container";
import { SectionTitle } from "../../components/section-title/section-title";
import { SocialNetworkCard } from "./social-network-card/social-network-card";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import "./social-network.css";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

export interface SocialNetworkCardProps {
  text: string;
  icon: IconDefinition;
  detail: string;
  onClick: () => void;
}

export interface SocialNetworkProps {
  itens?: SocialNetworkCardProps[];
}

export const SocialNetwork: FC<SocialNetworkProps> = ({ itens }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <div className="xvia-social-network">
      <Container className="xvia-social-network__container">
        <SectionTitle text={"Acompanhe nossas redes sociais"} />

        <Grid container spacing={3} justifyContent="space-betwen">
          {isLarge && <Grid item md={2} lg={2} xl={2} />}
          {itens?.map((i) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={isMedium ? 3 : 2}
                lg={2}
                xl={2}
                className="xvia-social-network__card"
                onClick={i.onClick}
              >
                <SocialNetworkCard icon={i.icon} detail={i.text} />
              </Grid>
            );
          })}
          {isLarge && <Grid item md={2} lg={2} xl={2} />}
        </Grid>
      </Container>
    </div>
  );
};
