import React, { FC } from "react";
import { Col, Row, Input } from "antd";
import { LinkList, LinkListProps } from "../../components/link-list/link-list";
import {
  IMidia,
  SocialMidia,
} from "../../components/social-midia/social-midia";
import { Container } from "../../components/container/container";
import { PhoneFilled, MailFilled } from "@ant-design/icons";

import "./footer.css";

export interface FooterProps {
  lists: LinkListProps[];
  social: IMidia[];
  contact: string;
  ramal: string;
  email: string;
  address: string;
}

const { Search } = Input;

const onSearch = (value: string) =>
  (window.location.href = `/app/catalog/search/${value}`);

export const Footer: FC<FooterProps> = ({
  lists,
  social,
  contact,
  email,
  ramal,
  address,
}) => {
  return (
    <div>
      <div className="rectangle-wrapper">
        <div className="rectangle-blue" />
        <div className="rectangle-yellow" />
        <div className="rectangle-red" />
        <div className="rectangle-green" />
      </div>
      <div className="xvia-footer">
        <Container className="xvia-footer__container">
          <img
            className={"xvia-footer__govpi__logo__hidden"}
            src={`${process.env.PUBLIC_URL}/images/svgs/logo_white.svg`}
            alt={"logogovpi"}
          />

          <div>
            <Row justify="space-between">
              {lists?.map((list, index) => {
                return (
                  <div>
                    <Col
                      className="xvia-footer__card"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={5}
                      xl={5}
                      xxl={5}
                      key={list.title}
                    >
                      <LinkList title={list.title} linkList={list.linkList} />
                    </Col>
                  </div>
                );
              })}

              <div>
                <p className="xvia-footer__service-box xvia-link-list__title">
                  Encontre um serviço
                </p>
                <Search
                  placeholder="Pesquise algo"
                  onSearch={onSearch}
                  className="xvia-footer__search-bar"
                />
              </div>
            </Row>
          </div>
        </Container>
        <div className="xvia-line-spacing-footer"></div>
        <Container className="xvia-footer__container">
          <div>
            <Row justify="space-between" gutter={[3, 34]} align="middle">
              <Col xs={24} sm={24} md={24} lg={3} xl={3} xxl={2}>
                <div>
                  <img
                    width={119}
                    className="xvia-footer__logo"
                    src={`${process.env.PUBLIC_URL}/images/svgs/logo-piaui-bandeira.png`}
                    alt="logomapa"
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
                <div>
                  <PhoneFilled style={{ color: "white" }} />
                  <span className="xvia-link-sub-footer-text">{contact}</span>
                  <PhoneFilled style={{ color: "white" }} />
                  <span className="xvia-link-sub-footer-text">{ramal}</span>
                </div>
                <div>
                  <MailFilled style={{ color: "white" }} />
                  <span className="xvia-link-sub-footer-text">{email}</span>
                </div>
              </Col>
              <Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={6} />
              <Col xs={12} sm={24} md={12} lg={6} xl={6} xxl={6}>
                <SocialMidia midiasList={social} />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};
