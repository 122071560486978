import React, { FC, MouseEventHandler } from "react";
import classNames from "classnames";
import "./news-card.css";

export interface NewsCardProps {
  title: string;
  categoryName: string;
  imageUrl: string;
  onClick: MouseEventHandler;
  color: string;
}

export const NewsCard: FC<NewsCardProps> = ({
  title,
  categoryName,
  imageUrl,
  onClick,
  color,
}) => {
  const containerClassName = classNames({
    "xvia-news-card": true,
  });

  return (
    <div>
      <button
        aria-label={title}
        onClick={onClick}
        className={containerClassName}
      >
        <div
          className="xvia-news-card__thumb"
          style={{
            backgroundImage: `url("${imageUrl}")`,
          }}
        />
      </button>
      <p className="xvia-news-card__category">{categoryName}</p>
      <div className="xvia-news-card__content" style={{ color }}>
        {title}
      </div>
    </div>
  );
};
