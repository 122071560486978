import React, { DetailedHTMLProps, FC } from "react";
import classNames from "classnames";
import "./container.css";

export interface ContainerProps
  extends DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  className?: string;
}

export const Container: FC<ContainerProps> = ({
  className,
  children,
  ...props
}) => {
  const containerClassName = classNames({
    "xvia-container": true,
    [className as string]: !!className,
  });
  return (
    <div className={containerClassName} {...props}>
      {children}
    </div>
  );
};
