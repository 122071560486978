import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./social-midia.css";

export interface IMidia {
  icon: string;
  link: string;
}

export interface SocialMidiaProps {
  midiasList: IMidia[];
}

export const SocialMidia: FC<SocialMidiaProps> = ({ midiasList }) => {
  return (
    <div>
      {midiasList.map((midia) => {
        return (
          <a
            href={midia.link}
            target={"_blank"}
            rel="noreferrer"
            key={midia.link}
          >
            <div className="xvia-social-midia__content_icon">
              <FontAwesomeIcon
                className="xvia-social-midia__icon"
                icon={`fa-brands ${midia.icon}` as IconProp}
              />
            </div>
          </a>
        );
      })}
    </div>
  );
};
