import React, { useCallback, useState } from "react";
import { SearchBar, SearchBarProps } from "./SearchBar";
import { useHistory } from "react-router-dom";

const groups = [
  {
    key: "/app/catalog/search",
    value: "Serviços",
  },
];

export const SearchBarContainer = () => {
  const history = useHistory();

  const [selectedGroup, setSelectedGroup] = useState(groups[0].key);
  const [text, setText] = useState();

  const onTextChange = useCallback(
    (text) => {
      setText(text);
    },
    [setText]
  );

  const onGroupChange = useCallback(
    (group) => {
      setSelectedGroup(group);
    },
    [setSelectedGroup]
  );

  const onClickSearch = useCallback(() => {
    if (!text) {
      return;
    }
    history.push(`/search/${text}`);
  }, [history, text]);

  const props: SearchBarProps = {
    placeholderText: "Qual serviço você procura?",
    onTextChange,
    onGroupChange,
    selectedGroup,
    onClickSearch,
  };
  return React.createElement(SearchBar, props);
};
