import React, { FC, useCallback } from "react";
import { Ombudsman, OmbudsmanProps } from "./ombudsman";
import CampaignIcon from "@mui/icons-material/Campaign";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import InfoIcon from "@mui/icons-material/Info";
import PostAddIcon from "@mui/icons-material/PostAdd";

export const OmbudsmanContainer: FC = () => {
  const baseUrl = process.env.PORTAL_URL;

  const openLink = useCallback((link) => {
    window.open(link, "_blank");
  }, []);

  const props: OmbudsmanProps = {
    itens: [
      {
        text: "Denúncia",
        icon: CampaignIcon,
        onClick: () => {
          openLink("https://falabr.cgu.gov.br/web/manifestacao/criar?tipo=1");
        },
      },
      {
        text: "Solicitação",
        icon: TouchAppIcon,
        onClick: () => {
          openLink("https://falabr.cgu.gov.br/web/manifestacao/criar?tipo=5");
        },
      },
      {
        text: "Sugestão",
        icon: QuestionAnswerIcon,
        onClick: () => {
          openLink("https://falabr.cgu.gov.br/web/manifestacao/criar?tipo=4");
        },
      },
      {
        text: "Simplifique",
        icon: PostAddIcon,
        onClick: () => {
          openLink(`${baseUrl}/app/app-simplifique`);
        },
      },
      {
        text: "Elogio",
        icon: ThumbUpIcon,
        onClick: () => {
          openLink("https://falabr.cgu.gov.br/web/manifestacao/criar?tipo=3");
        },
      },
      {
        text: "Reclamação",
        icon: ThumbDownIcon,
        onClick: () => {
          openLink("https://falabr.cgu.gov.br/web/manifestacao/criar?tipo=2");
        },
      },
      {
        text: "Acesso à Informação",
        icon: InfoIcon,
        onClick: () => {
          openLink("https://falabr.cgu.gov.br/web/manifestacao/criar?tipo=8");
        },
      },
    ],
  };

  return <Ombudsman {...props} />;
};
