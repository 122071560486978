import axios from "axios";

const apiUrl = process.env.BACKEND_ENDPOINT || "";
const axiosClient = axios.create({
  baseURL: `${apiUrl}`,
  timeout: 0,
  headers: {},
});
export interface ResultCategory {
  id?: string;
  name: string;
  catalogItems?: any;
}

export interface AccessProfile {
  id?: string;
  name: string;
  catalogItems?: any;
}

export interface CatalogDetail {
  isOnline: boolean;
  highlighted: boolean;
  id: string;
  description: string;
  isFree: boolean;
  link: string;
  name: string;
  imageUrl: string;
  tags: string[];
  targets: string[];
  slug: string;
  created_at: string;
  updated_at: string;
  isSelfService?: boolean;
  resultCategory?: ResultCategory;
  accessProfile?: AccessProfile;
  category: {
    id: string;
    slug: string;
    name: string;
    description: string;
    hidden: boolean;
    icon: string;
    orderIndex: number;
  };
  department: {
    id: string;
    slug: string;
    name: string;
    shortName: string;
    mission: string;
    vision: string;
    values: string;
    hidden: boolean;
    active: boolean;
  };
  info: {
    icon: string;
    id: string;
    title: string;
    text: string;
    orderIndex: number;
  }[];
  steps: {
    channel: string;
    id: string;
    name: string;
    description: string;
    htmlContent: string;
    orderIndex: number;
    documents: string[];
  }[];
  departmentSections: {
    id?: string;
    name: string;
    address: string;
    postalCode: string;
    district: string;
    city: string;
    state: string;
    countryCode: string;
    openingTime: string;
    mapUrl: string;
    active?: boolean;
    department: {
      id: string;
      slug: string;
      name: string;
      shortName: string;
      mission: string;
      vision: string;
      values: string;
      hidden: boolean;
      active: boolean;
    };
  }[];
  averageRatings?: {
    count: number;
    average: number;
  };
}

export async function getCatalog(
  accessToken?: string,
  slug?: string
): Promise<CatalogDetail> {
  const response = await axiosClient.get(`/v1/catalog/${slug}/load`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data;
}
