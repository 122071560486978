import React, { FC, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./info.css";

export interface InfoProps {
  text: string;
  icon?: ReactNode | IconProp;
}

export const Info: FC<InfoProps> = ({ text, icon }) => {
  return (
    <span className={"xvia-info"}>
      <FontAwesomeIcon
        className={"xvia-info__icon"}
        icon={`fa-light ${icon}` as IconProp}
      />

      <span className={"xvia-info__text"}>{text}</span>
    </span>
  );
};
