import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./rating.css";

export interface RatingProps {
  value: number;
  maximum?: number;
  readOnly: boolean;
  onClickItem?: (ratingValue: number) => void;
}

export const Rating: FC<RatingProps> = ({
  readOnly,
  value,
  onClickItem,
  maximum,
}) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => setLocalValue(value), [value]);

  const containerClassName = classNames({
    "xvia-rating": true,
  });

  const starClickHandler = useCallback(
    (num: number): MouseEventHandler<SVGSVGElement> => {
      return (evt) => {
        if (readOnly) {
          return;
        }
        onClickItem && onClickItem(num);
      };
    },
    [onClickItem, readOnly]
  );

  const starMouseEnterHandler = useCallback(
    (num: number): MouseEventHandler<SVGSVGElement> => {
      return (evt) => {
        if (readOnly) {
          return;
        }
        setLocalValue(num);
      };
    },
    [setLocalValue, readOnly]
  );

  const starMouseLeaveHandler = useCallback(() => {
    if (readOnly) {
      return;
    }
    setLocalValue(value);
  }, [value, readOnly]);

  return (
    <div className={containerClassName}>
      {Array.from(Array(maximum || 5).keys()).map((i) => (
        <FontAwesomeIcon
          className={classNames({
            "xvia-rating__icon": true,
            "xvia-rating__icon--disabled": i >= localValue,
            "xvia-rating__icon--clickable": !readOnly,
          })}
          icon={"fa-solid fa-star" as IconProp}
          onClick={starClickHandler(i + 1)}
          onMouseEnter={starMouseEnterHandler(i + 1)}
          onMouseLeave={starMouseLeaveHandler}
        />
      ))}
    </div>
  );
};
