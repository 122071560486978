import { Box, Stack, Typography } from "@mui/material";

export const DownloadAppMobile = () => {
  return (
    <Stack
      sx={(theme) => ({
        width: "100%",
        paddingY: "2rem",
        paddingX: "5rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:
          "linear-gradient(0deg, rgba(82, 178, 208, 0.08) -36.6%, rgba(0, 0, 0, 0.00) 68.17%)",

        gap: "10rem",

        [theme.breakpoints.down("md")]: {
          flexDirection: "column-reverse",
          gap: "1rem",
          justifyContent: "center",
          alignItems: "center",
        },
      })}
    >
      <Stack
        sx={(theme) => ({
          width: "25%",

          [theme.breakpoints.down("lg")]: {
            width: "45%",
          },

          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
        })}
      >
        <Typography
          variant="h3"
          sx={(theme) => ({
            color: "var(--xvia-color-primary)",
            fontSize: "1.35rem",
            fontWeight: "bold",
            width: "100%",

            [theme.breakpoints.down("sm")]: {
              textAlign: "center",
            },
          })}
        >
          Você já conhece o app para IOS e Android?
        </Typography>
        <Typography
          sx={(theme) => ({
            color: "var(--xvia-color-text)",
            fontSize: "1rem",
            mt: "1rem",
            width: "100%",

            [theme.breakpoints.down("sm")]: {
              textAlign: "center",
            },
          })}
        >
          Consulte várias informações sobre a sua CNH, seus Veículos, gerar seu
          CRLV Digital e muito mais.
        </Typography>

        <Stack
          sx={(theme) => ({
            display: "flex",
            gap: "1rem",
            flexDirection: "row",
            width: "100%",
            mt: "1rem",

            [theme.breakpoints.down(500)]: {
              flexDirection: "column",
            },
          })}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "10px",
              mt: "1rem",
              gap: "0.3rem",
              width: "100%",
              border: "1px solid #E0E3EB",
            }}
          >
            <Typography
              component="a"
              href="https://play.google.com/store/apps/details?id=br.gov.pi.pidigital&pli=1"
              rel="noreferrer"
            >
              <Box
                component="img"
                alt="play-store-button"
                src={`${process.env.PUBLIC_URL}/images/pngs/play-store.png`}
                sx={(theme) => ({
                  width: "9.375rem",
                  height: "100%",
                  objectFit: "contain",
                  objectPosition: "center",
                })}
              />
            </Typography>
            <span>ou</span>
            <Box
              component="img"
              alt="qr-code-play-store"
              src={`${process.env.PUBLIC_URL}/images/pngs/qr-code-gov-pi-cidadao-play-store.png`}
              sx={(theme) => ({
                width: "9.375rem",
                height: "100%",
                objectFit: "contain",
                objectPosition: "center",
              })}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: "white",
              paddingY: "1.25rem",
              paddingX: "1rem",
              borderRadius: "10px",
              mt: "1rem",
              gap: "0.5rem",
              width: "100%",
              border: "1px solid #E0E3EB",
            }}
          >
            <Typography
              component="a"
              href="https://apps.apple.com/in/app/gov-pi-cidad%C3%A3o/id6459582210"
              rel="noreferrer"
            >
              <Box
                component="img"
                alt="app-store-button"
                src={`${process.env.PUBLIC_URL}/images/pngs/app-store.png`}
                sx={(theme) => ({
                  width: "10rem",
                  height: "100%",
                  objectFit: "contain",
                  objectPosition: "center",
                })}
              />
            </Typography>
            <span>ou</span>
            <Box
              component="img"
              alt="qr-code-play-store"
              src={`${process.env.PUBLIC_URL}/images/pngs/qr-code-gov-pi-cidadao-app-store.png`}
              sx={(theme) => ({
                width: "9.375rem",
                height: "100%",
                objectFit: "contain",
                objectPosition: "center",
              })}
            />
          </Box>
        </Stack>
      </Stack>

      <Stack>
        <Box
          component="img"
          alt="Telas App"
          src={`${process.env.PUBLIC_URL}/images/pngs/phone-mockups-app.png`}
          sx={(theme) => ({
            width: "100%",
            height: "100%",
            objectFit: "contain",
            objectPosition: "center",

            [theme.breakpoints.down("sm")]: {},
          })}
        />
      </Stack>
    </Stack>
  );
};
