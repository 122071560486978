import { createElement, PropsWithChildren, useMemo } from "react";
import { MainBanner, MainBannerProps } from "./main-banner";

const images: string[] = [
  `${process.env.PUBLIC_URL}/images/banners/1.jpg`,
  `${process.env.PUBLIC_URL}/images/banners/2.jpg`,
  `${process.env.PUBLIC_URL}/images/banners/3.jpg`,
  `${process.env.PUBLIC_URL}/images/banners/4.jpg`,
];

export const MainBannerContainer = ({ children }: PropsWithChildren<{}>) => {
  const index = useMemo(
    () =>
      // Get a random value between 0 and the array length
      Math.floor(Math.random() * (images.length - 1)) + 1 - 1,
    []
  );

  const props: MainBannerProps = {
    imageBanner: images[index],
  };

  return createElement(MainBanner, props, children);
};
