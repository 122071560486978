import React, { FC, useCallback } from "react";
import { Container } from "../../components/container/container";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "./category-banner.css";
import { Category } from "@xvia/webgate-connect";

export interface CategoryProps {
  categoryList: Category[];
  onClickHeader: () => void;
  onClickCategory: (category: Category) => void;
  isOpen?: boolean;
}

export const CategoryBanner: FC<CategoryProps> = ({
  categoryList,
  isOpen,
  onClickHeader,
  onClickCategory,
}) => {
  const baseClassName = classNames({
    "xvia-category-banner": true,
    "xvia-category-banner--open": isOpen,
  });

  const onClickItem = useCallback(
    (category: Category) => {
      return () => onClickCategory(category);
    },
    [onClickCategory]
  );

  return (
    <Container className="xvia-category-services__container">
      <div className={baseClassName}>
        <button
          className="xvia-category-banner__header"
          style={{
            backgroundImage: `url("${process.env.PUBLIC_URL}/images/svgs/bg-categories.svg")`,
          }}
          onClick={onClickHeader}
        >
          <div
            className="xvia-category-banner__header-icon"
            style={{
              backgroundImage: `url("${process.env.PUBLIC_URL}/images/svgs/categories-icon.svg")`,
            }}
          />
          <div className="xvia-category-banner__header-text">
            <h1 className="xvia-category-banner__header-title">
              Todos os serviços por categoria
              <span>
                <FontAwesomeIcon
                  width={16}
                  height={16}
                  icon={isOpen ? faChevronUp : faChevronDown}
                />
              </span>
            </h1>
            <h2 className="xvia-category-banner__header-subtitle">
              Clique para expandir
            </h2>
          </div>
        </button>
        <div className="xvia-category-banner__content">
          <ul className="xvia-category-banner__list">
            {categoryList?.map((category) => {
              return (
                <li className="xvia-category-banner__list-item">
                  <button onClick={onClickItem(category)}>
                    {category.name}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Container>
  );
};
