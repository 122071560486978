import React from "react";
import { Container } from "@mui/material";
import { TopBar } from "../top-bar/top-bar";
import "./header-generic.css";

export interface HeaderProps {
  centerElement: React.ReactElement;
  rightElement: React.ReactElement;
}

export const HeaderGeneric = (props: HeaderProps) => (
  <header className="xvia-header-area">
    <TopBar />
    <div className="xvia-header-content-generic">
      <Container className="xvia-content-section xvia-header-content-section">
        <div className="xvia-header-logo">
          <a href="/">
            <picture>
              <source
                srcSet={`${process.env.PORTAL_URL}/assets/img/logo/logo-mobile.svg`}
                media="(max-width: 991px)"
              />
              <img
                src={`${process.env.PORTAL_URL}/assets/img/logo/logo.svg`}
                alt="Governo do Estado do Piauí"
              />
            </picture>
          </a>
        </div>
        <div className="xvia-header-center">{props.centerElement}</div>
        <div className="xvia-header-elements">{props.rightElement}</div>
      </Container>
    </div>
  </header>
);
